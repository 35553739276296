import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 39;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>100均のまな板と竹のまな板</li>
        <li>写真映えしてずっと使えるまな板を買いました</li>
        <li>古いカビだらけのまな板のメンテナンスに挑戦</li>
        <li>だいちゃんのカッティングボード制作</li>
        <li>
          <a
            href="https://kojimaya.work/"
            target="_blank"
            rel="noopener noreferrer"
          >
            箕澤屋のカウンターを作ってくれた小島屋さん
          </a>
        </li>
        <li>桜、檜、メープル、ウォルナットなど、木製まな板の木の種類</li>
        <li>やすりとオイルを使ったまな板のメンテナンスの仕方</li>
        <li>木製まな板のいいところ</li>
        <li>キッチン道具はずっと使えるからいいものを買いたい</li>
        <li>
          <a
            href="https://www.johnboos.com/product_group.asp?s=c&grp=JBC0053c%20%20&menu=2&mn=1&subno=6"
            target="_blank"
            rel="noopener noreferrer"
          >
            John Boos Blockのまな板専用オイルMystery Oil
          </a>
        </li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
